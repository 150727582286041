<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-select type="text"
                      filled
                      :items="services"
                      label="Послуга"
                      v-model="service_id"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-select type="text"
                      filled
                      :items="appliance_type_select"
                      label="Тип приладу"
                      v-model="appliance_type"
                      hide-details
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="appliance_type ? '' : 'req-star'"
                      color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <ApplianceModel :value="appliance_model_id"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                            :appliance_type="appliance_type"
                          :class="appliance_model_id ? '' : 'req-star'"
                            @autocompleteChange="modelChange"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text"
                          filled
                          label="Кількість"
                          v-model.number="item_count"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="item_count ? '' : 'req-star'"
                          v-integer
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <date-component
                label="Дата початку"
                v-model="date_start"
                req :class_="date_start ? '' : 'req-star'"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-component
                label="Дата закінчення"
                v-model="date_end" :req="false"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  CREATE_APPLIANCE_BY_FLAT,
  UPDATE_APPLIANCE_BY_FLAT,
  DELETE_APPLIANCE_BY_FLAT
} from "@/store/actions/appliance";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {appliance_type_select} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'appliance_by_flat_model_modal_delete'

export default {
  name: "HWP_Modal_ApplianceByFlat",
  mixins: [ModalComponentMixin],
  components: {
    ApplianceModel: () => import("@/components/autocomplite/ApplianceModel.vue"),
  },
  data() {
    return {
      appliance_type_select,
      flat_id: this.item.flat_id,
      service_id: this.item.service_id,
      appliance_type: this.item.appliance_type,
      appliance_model_id: this.item.appliance_model_id,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
      item_count: this.item.item_count,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.flat_id = this.item.flat_id
      this.service_id = this.item.service_id
      this.appliance_type = this.item.appliance_type
      this.appliance_model_id = this.item.appliance_model_id
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.item_count = this.item.item_count

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення моделі приладу: ${this.item.short_name}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    modelChange(payload) {
      this.appliance_model_id = payload.value
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля, котрі виділені червоним кольором - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        flat_id: this.flat_id,
        service_id: this.service_id,
        appliance_type: this.appliance_type,
        appliance_model_id: this.appliance_model_id,
        date_start: this.date_start,
        date_end: this.date_end,
        item_count: this.item_count,
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_APPLIANCE_BY_FLAT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_APPLIANCE_BY_FLAT, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.flat_id = payload.flat_id
              this.service_id = payload.service_id
              this.appliance_type = payload.appliance_type
              this.appliance_model_id = payload.appliance_model_id
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.item_count = payload.item_count
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_APPLIANCE_BY_FLAT, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll'
    }),
  }
}
</script>
